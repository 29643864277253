{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Tilføj aftale",
    "downloadChecklist": "Tjekliste",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Ingen matchede oplevelser",
        "noExperiences": "Ingen matchede oplevelser",
        "noInactiveExperienceMatchingFilter": "Ingen matchede inaktive oplevelser"
      }
    },
    "warnings": {
      "noExperiences": "Ingen matchede oplevelser"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Lynhandlinger"
        }
      },
      "navAccount": {
        "help": {
          "label": "Hjælp"
        },
        "switch": {
          "label": "Skift til"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "Dag | Dage",
    "hours": "time | Timer",
    "minutes": "Min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Gå til oversigt",
        "description": "Din konto mangler de nødvendige rettigheder til at se denne side",
        "title": "Du har ikke tilladelse til at se denne side"
      },
      "serverError": {
        "cta": "Prøv igen",
        "description": "På grund af en intern serverfejl er vi ikke i stand til at efterkomme din forespørgsel. Vi er blevet oplyst om det. Prøv venligst igen senere.",
        "title": "Ups! Der opstår en vil fejl"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Forhandleren har ingen aktiverede wallets"
      }
    },
    "validationErrors": {
      "invalidIban": "Ugyldigt IBAN",
      "notAValidEmail": "Dette felt er ikke nogen gyldig e-mail",
      "notAValidMoneyAmount": "Ugyldigt pengebeløb",
      "notEmpty": "Feltet må ikke være tomt",
      "notIdenticalIban": "Bekræft at IBAN skal være det samme som IBAN",
      "unrecognisedDateTime": "Kunne ikke genkende dato og klokkeslæt"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Vælg, hvilken oplevelse du vil tildele ny tilgængelighed.",
      "title": "Føj en aftale til oplevelsen"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Opdater",
      "title": "En ny version af bookingkit er tilgængelig"
    }
  },
  "pagination": {
    "next": "Næste",
    "previous": "Forrige"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Tilføj aftale",
    "disableHere": "Deaktiver genveje her",
    "enableShortcuts": "Aktivér genveje",
    "keyboardShortcuts": "Tastaturgenveje",
    "ok": "Ok",
    "powerShortcuts": "Strømgenveje",
    "search": "Søg",
    "searchShortcutToastInfoMac": "Tryk på ⌘ + F igen for at aktivere den oprindelige browsersøgning",
    "searchShortcutToastInfoWindows": "Tryk på Ctrl + F igen for at aktivere den oprindelige browsersøgning",
    "toastMessage": "Genveje er aktiveret som standard.",
    "vendorShortcutToastInfoMac": "Tryk på ⌘ + M igen for at aktivere den oprindelige browsergenvej",
    "vendorShortcutToastInfoWindows": "Tryk på Ctrl + M igen for at aktivere den oprindelige browsergenvej",
    "vendorSwitch": "Skift af leverandør"
  },
  "search": {
    "accepted": "ACCEPTERET",
    "archived": "ARKIVERET",
    "canceled": "ANNULLERET",
    "coupon": "Kupon",
    "declined": "AFVIST",
    "expired": "UDLØBET",
    "failure": "MISLYKKET",
    "feature": "Funktion",
    "flexibleTicket": "Fleksibel billet",
    "invalid": "Ugyldig",
    "noResultFound": "Der findes ingen resultater",
    "noResultFoundMessage": "Din søgning har ikke givet nogen resultater. Prøv venligst en anden måde.",
    "open": "ANMODNING",
    "order": "Ordre",
    "pending": "AFVENTER",
    "reserved": "RESERVERET",
    "search": "Søg",
    "searchEntryMessage": "Du kan søge:",
    "searchError": "Noget gik galt..",
    "searchErrorMessage": "Vi arbejder på at løse problemet. Prøv igen snart.",
    "searchFeatureEntryMessage": "Funktioner efter navne.",
    "searchOrderEntryMessage": "ordrer fra ids, kunde eller andre ordrerelaterede data",
    "searchScopes": {
      "all": "Alle",
      "coupons": "Kuponer",
      "features": "Funktioner",
      "flexibleTickets": "Fleksible billetter",
      "orders": "Ordrer",
      "vouchers": "Værdibeviser"
    },
    "searchVoucherEntryMesaage": "Værdibeviser efter kode, titel eller beskrivelse",
    "searchVoucherEntryMessage": "Værdibeviser pr. kode, titel eller beskrivelse",
    "ticketTypes": {
      "all": "Alle",
      "coupons": "Kuponer",
      "features": "Funktioner",
      "flexibleTickets": "Fleksible billetter",
      "orders": "Ordrer",
      "vouchers": "Værdibeviser"
    },
    "valid": "Gyldig",
    "voucher": "Værdibevis"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Virksomhedsdata"
      },
      "contract": {
        "label": "Kontrakt"
      },
      "main": {
        "label": "konto"
      },
      "profile": {
        "label": "Profil"
      },
      "signOut": {
        "label": "Log ud"
      },
      "userManagement": {
        "label": "Brugeradministration"
      },
      "vendorSwitcher": {
        "label": "leverandørskifter"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API-betalinger"
      },
      "applicaitonLimits": {
        "label": "Begrænsninger for anvendelse"
      },
      "application": {
        "label": "Program"
      },
      "applicationLimits": {
        "label": "Begrænsninger for anvendelse"
      },
      "assessments": {
        "label": "Vurderinger"
      },
      "changeRates": {
        "label": "Skift satser"
      },
      "chargeVouchers": {
        "label": "Opkræv betaling for værdibeviser"
      },
      "claimAccount": {
        "label": "Gør krav på konto"
      },
      "createAccount": {
        "label": "Opret konto"
      },
      "dashboard": {
        "label": "Dashboard"
      },
      "disableEventDates": {
        "label": "Deaktiver begivenhedsdatoer"
      },
      "emailConfiguration": {
        "label": "Konfiguration af e-mail"
      },
      "importCoupons": {
        "label": "Importer kuponer"
      },
      "importOrders": {
        "label": "Importer ordrer"
      },
      "importVouchers": {
        "label": "Importer værdibeviser"
      },
      "inviteNew": {
        "label": "Inviter en ny forhandler"
      },
      "invoices": {
        "label": "Fakturaer"
      },
      "KYCUnverified": {
        "label": "KYC ubekræftet"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "Administrer API"
      },
      "manageBkUsers": {
        "label": "Administrer bk-brugere"
      },
      "mangopayDisputes": {
        "label": "Mangopay-anfægtelser"
      },
      "marketingPartners": {
        "label": "Marketingpartnere"
      },
      "outstandingPayments": {
        "label": "Udestående betalinger"
      },
      "paymentRequests": {
        "label": "Betalingsanmodninger"
      },
      "payoutChannels": {
        "label": "Udbetalingskanaler"
      },
      "paypalPaymentRequests": {
        "label": "PayPal betalingsanmodninger"
      },
      "platforms": {
        "label": "Platforme"
      },
      "provider": {
        "label": "Udbyder"
      },
      "rates": {
        "label": "Priser"
      },
      "resellerPaymentStatus": {
        "label": "Status for forhandlerbetaling"
      },
      "setupFee": {
        "label": "Oprettelsesgebyr"
      },
      "translationTool": {
        "label": "Oversættelsesværktøj"
      },
      "unknownPaymentrequests": {
        "label": "Ukendte betalingsanmodninger"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Dagsudsigt"
      },
      "feed": {
        "label": "Kalender-feed"
      },
      "main": {
        "label": "Kalender"
      },
      "month": {
        "label": "Månedsvisning"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Bundter"
      },
      "experiences": {
        "label": "Oplevelser"
      },
      "flexibleOffers": {
        "label": "Fleksible tilbud"
      },
      "main": {
        "label": "Lager"
      },
      "products": {
        "label": "Produkter"
      },
      "resources": {
        "label": "Ressourcer"
      }
    },
    "marketplace": {
      "main": {
        "label": "App Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Fleksible billetter"
      },
      "main": {
        "label": "Ordrer"
      },
      "orders": {
        "label": "Ordrer"
      },
      "participants": {
        "label": "Deltagere"
      },
      "promotionalCoupons": {
        "label": "Salgsfremmende kuponer"
      },
      "requests": {
        "label": "Forespørgsler"
      },
      "vouchers": {
        "label": "Værdibeviser"
      }
    },
    "overview": {
      "main": {
        "label": "Oversigt"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Affilierede links"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Inviter en ny forhandler"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Mine partnere"
      },
      "partnerRates": {
        "label": "Partnerpriser"
      },
      "platforms": {
        "label": "Platforme"
      },
      "promote": {
        "label": "Fremme lokale oplevelser"
      },
      "reachOrders": {
        "label": "Ordrer"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Aggreger statistikker"
      },
      "exports": {
        "label": "Eksport"
      },
      "main": {
        "label": "Rapporter"
      },
      "monthlyStatement": {
        "label": "Månedlig opgørelse"
      },
      "statements": {
        "label": "Opgørelser"
      },
      "statistics": {
        "label": "Statistik"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Forhandlere"
      },
      "marketplace": {
        "label": "Markedsplads"
      },
      "myPartners": {
        "label": "Mine partnere"
      },
      "orders": {
        "label": "Ordrer"
      },
      "platforms": {
        "label": "Forhandlernes integrationer"
      },
      "promote": {
        "label": "Gør reklame for lokale oplevelser"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Konfiguration af betaling"
      },
      "emailCampaigns": {
        "label": "E-mail-kampagner"
      },
      "localExperiences": {
        "label": "Lokale oplevelser"
      },
      "main": {
        "label": "Sælg"
      },
      "newWidgets": {
        "label": "Ny Widgets-konfigurator"
      },
      "paymentOptions": {
        "label": "Betalingsmuligheder"
      },
      "paymentProviders": {
        "label": "Betalingsudbydere"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kalender-feed"
      },
      "emailConfiguration": {
        "label": "Konfiguration af e-mail"
      },
      "invoiceLayout": {
        "label": "Layout af faktura"
      },
      "invoiceNNumber": {
        "label": "Fakturanummer"
      },
      "invoiceNumber": {
        "label": "Fakturanummer"
      },
      "main": {
        "label": "Indstillinger"
      },
      "modules": {
        "label": "Moduler"
      },
      "ticketConfiguration": {
        "label": "Konfiguration af billet"
      },
      "translations": {
        "label": "Oversættelser"
      },
      "vat": {
        "label": "Moms"
      },
      "voucherConfiguration": {
        "label": "Konfiguration af værdibeviser"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App Marketplace"
      },
      "appointment": {
        "label": "Tilføj aftale"
      },
      "booking": {
        "label": "Booking"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Kontakt support"
      },
      "copyToClipboard": {
        "failure": "Kunne ikke kopiere link til kreditor",
        "success": "Specifikt link kopieret til leverandør:"
      },
      "date": {
        "label": "Dato"
      },
      "experience": {
        "label": "Oplevelse"
      },
      "knowledgeBase": {
        "label": "Vidensbase",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Onboarding-guide (skal bekræftes)"
      },
      "powerShortcuts": {
        "label": "Strømgenveje"
      }
    },
    "updates": {
      "main": {
        "label": "Produkt opdateringer"
      },
      "mainBadge": {
        "label": "Nye"
      }
    },
    "wallet": {
      "main": {
        "label": "Wallet"
      },
      "partnerPayments": {
        "label": "Betalinger fra partnere"
      },
      "verification": {
        "label": "Kontrol"
      },
      "walletManagement": {
        "label": "Wallet-administration"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Tilgængelige leverandører",
    "dismiss": "Afvis",
    "navigate": "Naviger",
    "noResult": "Der findes ingen resultater",
    "recentUsed": "Brugt fornylig",
    "resultsLabel": "Fundne leverandører",
    "searchError": "Noget gik galt..",
    "searchErrorMessage": "Vi arbejder på at løse problemet. Prøv igen snart.",
    "searchForVendor": "Søg efter leverandør",
    "select": "Vælg",
    "storeResultsLabel": "Tilgængelige leverandører",
    "supplierAlreadyInUseError": "Du bruger i øjeblikket denne leverandørkonto",
    "switchVendor": "Skift leverandør",
    "wishmorning": "Godmorgen"
  }
}