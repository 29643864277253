{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Add appointment",
    "downloadChecklist": "Checklist",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "No matched experiences",
        "noExperiences": "No matched experiences",
        "noInactiveExperienceMatchingFilter": "No matched inactive experiences"
      }
    },
    "warnings": {
      "noExperiences": "No matched experiences"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Quick actions"
        }
      },
      "navAccount": {
        "help": {
          "label": "Help"
        },
        "switch": {
          "label": "Switch to"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "day | days",
    "hours": "hour | hours",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Go to overview",
        "description": "Your account is lacking the rights necessary to view this page",
        "title": "You’re not allowed to view this page"
      },
      "serverError": {
        "cta": "Try again",
        "description": "Due to internal server error, we were not able to complete your request. We’ve been notified about it. Please try again after some time.",
        "title": "Ooops, a wild error appears"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Vendor does not have any wallets activated"
      }
    },
    "validationErrors": {
      "invalidIban": "Invalid IBAN",
      "notAValidEmail": "The field is not a valid email",
      "notAValidMoneyAmount": "Invalid money amount",
      "notEmpty": "The field can not be empty",
      "notIdenticalIban": "Confirm IBAN needs to be same as IBAN",
      "unrecognisedDateTime": "Unrecognised Date and Time"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Choose to which experience you want to assign new availability.",
      "title": "Add appointment to the experience"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Refresh",
      "title": "A new version of bookingkit is available"
    }
  },
  "pagination": {
    "next": "Next",
    "previous": "Previous"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Add appointment",
    "disableHere": "Disable shortcuts here",
    "enableShortcuts": "Enable shortcuts",
    "keyboardShortcuts": "Keyboard shortcuts",
    "ok": "OK",
    "powerShortcuts": "Power shortcuts",
    "search": "Search",
    "searchShortcutToastInfoMac": "Press ⌘ + F again to activate the native browser search",
    "searchShortcutToastInfoWindows": "Press Ctrl + F again to activate the native browser search",
    "toastMessage": "Shortcuts are enable by default.",
    "vendorShortcutToastInfoMac": "Press ⌘ + M again to activate the native browser shortcut",
    "vendorShortcutToastInfoWindows": "Press Ctrl + M again to activate the native browser shortcut",
    "vendorSwitch": "Vendor switch"
  },
  "search": {
    "accepted": "ACCEPTED",
    "archived": "ARCHIVED",
    "canceled": "CANCELED",
    "coupon": "Coupon",
    "declined": "DECLINED",
    "expired": "EXPIRED",
    "failure": "FAILURE",
    "feature": "Feature",
    "flexibleTicket": "Flexible ticket",
    "invalid": "Invalid",
    "noResultFound": "No result found",
    "noResultFoundMessage": "Your search does not match any result. Please try another way.",
    "open": "REQUEST",
    "order": "Order",
    "pending": "PENDING",
    "reserved": "RESERVED",
    "search": "Search",
    "searchEntryMessage": "You can search:",
    "searchError": "Something went wrong..",
    "searchErrorMessage": "We are working on fixing the problem. Please try again soon.",
    "searchFeatureEntryMessage": "Features by names.",
    "searchOrderEntryMessage": "orders by ids, customer or other order related data;",
    "searchScopes": {
      "all": "All",
      "coupons": "Coupons",
      "features": "Features",
      "flexibleTickets": "Flexible tickets",
      "orders": "Orders",
      "vouchers": "Vouchers"
    },
    "searchVoucherEntryMesaage": "Vouchers by code ,title or description;",
    "searchVoucherEntryMessage": "vouchers by code ,title or description;",
    "ticketTypes": {
      "all": "All",
      "coupons": "Coupons",
      "features": "Features",
      "flexibleTickets": "Flexible tickets",
      "orders": "Orders",
      "vouchers": "Vouchers"
    },
    "valid": "Valid",
    "voucher": "Voucher"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Company data"
      },
      "contract": {
        "label": "Contract"
      },
      "main": {
        "label": "account"
      },
      "profile": {
        "label": "Profile"
      },
      "signOut": {
        "label": "Sign out"
      },
      "userManagement": {
        "label": "User management"
      },
      "vendorSwitcher": {
        "label": "vendor switcher"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API payments"
      },
      "applicaitonLimits": {
        "label": "Applicaiton limits"
      },
      "application": {
        "label": "Application"
      },
      "applicationLimits": {
        "label": "Application limits"
      },
      "assessments": {
        "label": "Assessments"
      },
      "changeRates": {
        "label": "Change rates"
      },
      "chargeVouchers": {
        "label": "Charge vouchers"
      },
      "claimAccount": {
        "label": "Claim account"
      },
      "createAccount": {
        "label": "Create account"
      },
      "dashboard": {
        "label": "Dashboard"
      },
      "disableEventDates": {
        "label": "Disable event dates"
      },
      "emailConfiguration": {
        "label": "Email configuration"
      },
      "importCoupons": {
        "label": "Import coupons"
      },
      "importOrders": {
        "label": "Import orders"
      },
      "importVouchers": {
        "label": "Import vouchers"
      },
      "inviteNew": {
        "label": "Invite a new Reseller"
      },
      "invoices": {
        "label": "Invoices"
      },
      "KYCUnverified": {
        "label": "KYC Unverified"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "Manage API"
      },
      "manageBkUsers": {
        "label": "Manage bk users"
      },
      "mangopayDisputes": {
        "label": "Mangopay disputes"
      },
      "marketingPartners": {
        "label": "Marketing Partners"
      },
      "outstandingPayments": {
        "label": "Outstanding payments"
      },
      "paymentRequests": {
        "label": "Payment requests"
      },
      "payoutChannels": {
        "label": "Payout Channels"
      },
      "paypalPaymentRequests": {
        "label": "PayPal payment requests"
      },
      "platforms": {
        "label": "Platforms"
      },
      "provider": {
        "label": "Provider"
      },
      "rates": {
        "label": "Rates"
      },
      "resellerPaymentStatus": {
        "label": "Reseller Payment Status"
      },
      "setupFee": {
        "label": "Setup Fee"
      },
      "translationTool": {
        "label": "Translation Tool"
      },
      "unknownPaymentrequests": {
        "label": "Unknown Payment requests"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Day view"
      },
      "feed": {
        "label": "Calendar feed"
      },
      "main": {
        "label": "Calendar"
      },
      "month": {
        "label": "Month view"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Bundles"
      },
      "experiences": {
        "label": "Experiences"
      },
      "flexibleOffers": {
        "label": "Flexible offers"
      },
      "main": {
        "label": "Inventory"
      },
      "products": {
        "label": "Products"
      },
      "resources": {
        "label": "Resources"
      }
    },
    "marketplace": {
      "main": {
        "label": "App Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Flexible tickets"
      },
      "main": {
        "label": "Orders"
      },
      "orders": {
        "label": "Orders"
      },
      "participants": {
        "label": "Participants"
      },
      "promotionalCoupons": {
        "label": "Promotional Coupons"
      },
      "requests": {
        "label": "Requests"
      },
      "vouchers": {
        "label": "Vouchers"
      }
    },
    "overview": {
      "main": {
        "label": "Overview"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Affiliate links"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Invite a new Reseller"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "My partners"
      },
      "partnerRates": {
        "label": "Partner rates"
      },
      "platforms": {
        "label": "Platforms"
      },
      "promote": {
        "label": "Promote local experiences"
      },
      "reachOrders": {
        "label": "Orders"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Aggregate statistics"
      },
      "exports": {
        "label": "Exports"
      },
      "main": {
        "label": "Reports"
      },
      "monthlyStatement": {
        "label": "Monthly statement"
      },
      "statements": {
        "label": "Statements"
      },
      "statistics": {
        "label": "Statistics"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Resellers"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "My partners"
      },
      "orders": {
        "label": "Orders"
      },
      "platforms": {
        "label": "Resellers' integrations"
      },
      "promote": {
        "label": "Promote local experiences"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Checkout configuration"
      },
      "emailCampaigns": {
        "label": "Email campaigns"
      },
      "localExperiences": {
        "label": "Local experiences"
      },
      "main": {
        "label": "Sell"
      },
      "newWidgets": {
        "label": "New Widgets"
      },
      "paymentOptions": {
        "label": "Payment options"
      },
      "paymentProviders": {
        "label": "Payment providers"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Calendar feed"
      },
      "emailConfiguration": {
        "label": "Email configuration"
      },
      "invoiceLayout": {
        "label": "Invoice layout"
      },
      "invoiceNNumber": {
        "label": "Invoice number"
      },
      "invoiceNumber": {
        "label": "Invoice number"
      },
      "main": {
        "label": "Settings"
      },
      "modules": {
        "label": "Modules"
      },
      "ticketConfiguration": {
        "label": "Ticket configuration"
      },
      "translations": {
        "label": "Translations"
      },
      "vat": {
        "label": "VAT"
      },
      "voucherConfiguration": {
        "label": "Voucher configuration"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App Marketplace"
      },
      "appointment": {
        "label": "Add appointment"
      },
      "booking": {
        "label": "Booking"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit reach"
      },
      "contactSupport": {
        "label": "Contact support"
      },
      "copyToClipboard": {
        "failure": "Failed to copy link for vendor",
        "success": "Specific link copied for vendor: "
      },
      "date": {
        "label": "Date"
      },
      "experience": {
        "label": "Experience"
      },
      "knowledgeBase": {
        "label": "Knowledge base",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Onboarding wizard (TBC)"
      },
      "powerShortcuts": {
        "label": "Power shortcuts"
      }
    },
    "updates": {
      "main": {
        "label": "Product updates"
      },
      "mainBadge": {
        "label": "New"
      }
    },
    "wallet": {
      "main": {
        "label": "Wallet"
      },
      "partnerPayments": {
        "label": "Partner Payments"
      },
      "verification": {
        "label": "Verification"
      },
      "walletManagement": {
        "label": "Wallet management"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Available vendors",
    "dismiss": "Dismiss",
    "navigate": "Navigate",
    "noResult": "No result found",
    "recentUsed": "Recently used",
    "resultsLabel": "Found vendors",
    "searchError": "Something went wrong..",
    "searchErrorMessage": "We are working on fixing the problem. Please try again soon.",
    "searchForVendor": "Search for vendor",
    "select": "Select",
    "storeResultsLabel": "Available vendors",
    "supplierAlreadyInUseError": "You are currently using this vendor account",
    "switchVendor": "Switch vendor",
    "wishmorning": "Good morning"
  }
}