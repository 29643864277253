{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Lägg till tidsbokning ",
    "downloadChecklist": "Kom-ihåg-lista",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Inga upplevelser som matchar",
        "noExperiences": "Inga upplevelser som matchar",
        "noInactiveExperienceMatchingFilter": "Inga matchade inaktiva upplevelser"
      }
    },
    "warnings": {
      "noExperiences": "Inga upplevelser som matchar"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Snabba åtgärder "
        }
      },
      "navAccount": {
        "help": {
          "label": "Hjälp"
        },
        "switch": {
          "label": "Byt till"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "dag | dagar",
    "hours": "timme | timmar",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Gå till översikt",
        "description": "Ditt konto saknar de rättigheter som krävs för att se den här sidan",
        "title": "Du får inte se den här sidan"
      },
      "serverError": {
        "cta": "Försök igen",
        "description": "På grund av ett internt serverfel kan vi inte slutföra din begäran. Vi har meddelats om detta. Försök igen om en stund.",
        "title": "Ooops, ett vilt fel visas "
      },
      "walletManagement": {
        "noWalletErrorMessage": "Säljaren har inga plånböcker aktiverade"
      }
    },
    "validationErrors": {
      "invalidIban": "Ogiltigt IBAN-nummer",
      "notAValidEmail": "Fältet är inte ett giltigt e-postmeddelande ",
      "notAValidMoneyAmount": "Ogiltigt belopp",
      "notEmpty": "Detta fält kan inte vara tomt",
      "notIdenticalIban": "Bekräfta att IBAN måste vara samma som IBAN",
      "unrecognisedDateTime": "Datum och tid kändes inte igen"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Välj till vilken upplevelse du vill tilldela den nya tillgängligheten.",
      "title": "Lägg till tidsbokning i upplevelse"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Uppdatera",
      "title": "En ny version av bookingkit är tillgänglig"
    }
  },
  "pagination": {
    "next": "Nästa",
    "previous": "Föregående"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Lägg till tidsbokning",
    "disableHere": "Avaktivera genvägar här",
    "enableShortcuts": "Aktivera genvägar",
    "keyboardShortcuts": "Snabbtangenter",
    "ok": "OK",
    "powerShortcuts": "Snabbkommandon",
    "search": "Sök",
    "searchShortcutToastInfoMac": "Tryck på ⌘ + F igen för att aktivera den inbyggda webbläsarsökningen",
    "searchShortcutToastInfoWindows": "Tryck på Ctrl + F igen för att aktivera den inbyggda webbläsarsökningen",
    "toastMessage": "Genvägar är aktiverade som standard.",
    "vendorShortcutToastInfoMac": "Tryck på ⌘ + M igen för att aktivera webbläsarens inbyggda genväg",
    "vendorShortcutToastInfoWindows": "Tryck på Ctrl + M igen för att aktivera webbläsarens inbyggda genväg",
    "vendorSwitch": "Leverantörsbyte"
  },
  "search": {
    "accepted": "ACCEPTERAD",
    "archived": "ARKIVERAD",
    "canceled": "ANNULLERAD",
    "coupon": "Kupong",
    "declined": "NEKAD",
    "expired": "UTGÅNGEN",
    "failure": "FEL",
    "feature": "Funktion",
    "flexibleTicket": "Flexibel biljett",
    "invalid": "Ogiltig",
    "noResultFound": "Hittade inget resultat",
    "noResultFoundMessage": "Din sökning matchar inte något resultat. Försök på ett annat sätt.",
    "open": "ÖPPEN",
    "order": "Order",
    "pending": "PÅGÅENDE",
    "reserved": "RESERVERAD",
    "search": "Sök",
    "searchEntryMessage": "Du kan söka:",
    "searchError": "Något gick fel ...",
    "searchErrorMessage": "Vi arbetar med att lösa problemet. Försök snart igen.",
    "searchFeatureEntryMessage": "Funktioner efter namn.",
    "searchOrderEntryMessage": "order efter ID, kund eller annan orderrelaterad data;",
    "searchScopes": {
      "all": "Alla",
      "coupons": "Kuponger",
      "features": "Funktioner",
      "flexibleTickets": "Flexibla biljetter",
      "orders": "Order ",
      "vouchers": "Vouchers"
    },
    "searchVoucherEntryMesaage": "Vouchers efter kod, titel eller beskrivning;",
    "searchVoucherEntryMessage": "vouchers efter kod, titel eller beskrivning;",
    "ticketTypes": {
      "all": "Alla",
      "coupons": "Kuponger",
      "features": "Funktioner",
      "flexibleTickets": "Flexibla biljetter",
      "orders": "Order ",
      "vouchers": "Kuponger"
    },
    "valid": "Giltig",
    "voucher": "Voucher"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Företagsuppgifter"
      },
      "contract": {
        "label": "Avtal"
      },
      "main": {
        "label": "konto"
      },
      "profile": {
        "label": "Profil"
      },
      "signOut": {
        "label": "Logga ut"
      },
      "userManagement": {
        "label": "Användaradministration"
      },
      "vendorSwitcher": {
        "label": "leverantörsbytare"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API-betalningar"
      },
      "applicaitonLimits": {
        "label": "Applikationsbegränsningar"
      },
      "application": {
        "label": "Program"
      },
      "applicationLimits": {
        "label": "Applikationsbegränsningar"
      },
      "assessments": {
        "label": "Bedömningar"
      },
      "changeRates": {
        "label": "Ändra priser"
      },
      "chargeVouchers": {
        "label": "Debitera vouchers"
      },
      "claimAccount": {
        "label": "Tilldela konto"
      },
      "createAccount": {
        "label": "Skapa konto"
      },
      "dashboard": {
        "label": "Instrumentpanelen"
      },
      "disableEventDates": {
        "label": "Inaktivera evenemangsdatum"
      },
      "emailConfiguration": {
        "label": "Konfiguration av e-post"
      },
      "importCoupons": {
        "label": "Importera kuponger"
      },
      "importOrders": {
        "label": "Importera beställningar"
      },
      "importVouchers": {
        "label": "Importera vouchers"
      },
      "inviteNew": {
        "label": "Bjud in en ny återförsäljare"
      },
      "invoices": {
        "label": "Fakturor"
      },
      "KYCUnverified": {
        "label": "KYC Overifierad"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "Hantera API"
      },
      "manageBkUsers": {
        "label": "Administrera bk-användare"
      },
      "mangopayDisputes": {
        "label": "Mangopay tvister"
      },
      "marketingPartners": {
        "label": "Marknadsföringspartners"
      },
      "outstandingPayments": {
        "label": "Utestående betalningar"
      },
      "paymentRequests": {
        "label": "Begäran om utbetalning"
      },
      "payoutChannels": {
        "label": "Utbetalningskanaler"
      },
      "paypalPaymentRequests": {
        "label": "Paypal betalningsförfrågningar"
      },
      "platforms": {
        "label": "Plattformar"
      },
      "provider": {
        "label": "Leverantör"
      },
      "rates": {
        "label": "Priser"
      },
      "resellerPaymentStatus": {
        "label": "Betalningsstatus för återförsäljare"
      },
      "setupFee": {
        "label": "Installationsavgift"
      },
      "translationTool": {
        "label": "Översättningsverktyg"
      },
      "unknownPaymentrequests": {
        "label": "Okända betalningsförfrågningar"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Dagsvy "
      },
      "feed": {
        "label": "Kalenderflöde "
      },
      "main": {
        "label": "Kalender "
      },
      "month": {
        "label": "Månadsvy "
      }
    },
    "inventory": {
      "bundles": {
        "label": "Paket"
      },
      "experiences": {
        "label": "Upplevelser"
      },
      "flexibleOffers": {
        "label": "Flexibla erbjudanden"
      },
      "main": {
        "label": "Inventering"
      },
      "products": {
        "label": "Produkter"
      },
      "resources": {
        "label": "Resurser"
      }
    },
    "marketplace": {
      "main": {
        "label": "App Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Flexibla biljetter"
      },
      "main": {
        "label": "Order "
      },
      "orders": {
        "label": "Order "
      },
      "participants": {
        "label": "Deltagare "
      },
      "promotionalCoupons": {
        "label": "Kampanjkuponger"
      },
      "requests": {
        "label": "Begäranden"
      },
      "vouchers": {
        "label": "Vouchers"
      }
    },
    "overview": {
      "main": {
        "label": "Översikt"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Affilierade länkar"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Bjud in en ny återförsäljare"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Mina partners"
      },
      "partnerRates": {
        "label": "Priser för partners"
      },
      "platforms": {
        "label": "Plattformar"
      },
      "promote": {
        "label": "Främja lokala upplevelser"
      },
      "reachOrders": {
        "label": "Order"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Aggregerad statistik"
      },
      "exports": {
        "label": "Exporter"
      },
      "main": {
        "label": "Rapporter"
      },
      "monthlyStatement": {
        "label": "Månadsredovisning"
      },
      "statements": {
        "label": "Redovisningar"
      },
      "statistics": {
        "label": "Statistik"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Återförsäljare"
      },
      "marketplace": {
        "label": "Marknadsplats"
      },
      "myPartners": {
        "label": "Mina partners"
      },
      "orders": {
        "label": "Order"
      },
      "platforms": {
        "label": "Återförsäljares integrationer"
      },
      "promote": {
        "label": "Marknadsför lokala upplevelser"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Konfiguration av kassa"
      },
      "emailCampaigns": {
        "label": "E-postkampanjer"
      },
      "localExperiences": {
        "label": "Lokala upplevelser"
      },
      "main": {
        "label": "Sälja"
      },
      "newWidgets": {
        "label": "Ny konfigurator för widgets"
      },
      "paymentOptions": {
        "label": "Betalningsalternativ"
      },
      "paymentProviders": {
        "label": "Betalningsleverantörer"
      },
      "widgets": {
        "label": "Widgetar"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kalenderflöde "
      },
      "emailConfiguration": {
        "label": "Konfiguration av e-post"
      },
      "invoiceLayout": {
        "label": "Fakturalayout"
      },
      "invoiceNNumber": {
        "label": "Fakturanummer"
      },
      "invoiceNumber": {
        "label": "Fakturanummer"
      },
      "main": {
        "label": "Inställningar"
      },
      "modules": {
        "label": "Moduler"
      },
      "ticketConfiguration": {
        "label": "Konfiguration av biljetter"
      },
      "translations": {
        "label": "Översättningar"
      },
      "vat": {
        "label": "Moms"
      },
      "voucherConfiguration": {
        "label": "Konfiguration av vouchers"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App Marketplace"
      },
      "appointment": {
        "label": "Lägg till tidsbokning"
      },
      "booking": {
        "label": "Bokning"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit reach"
      },
      "contactSupport": {
        "label": "Kontakta support"
      },
      "copyToClipboard": {
        "failure": "Det gick inte att kopiera länken för leverantören",
        "success": "Specifik länk kopierad för leverantör:"
      },
      "date": {
        "label": "Datum"
      },
      "experience": {
        "label": "Upplevelse"
      },
      "knowledgeBase": {
        "label": "Kunskapsbas",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Registreringsguide (TBC)"
      },
      "powerShortcuts": {
        "label": "Snabbkommandon"
      }
    },
    "updates": {
      "main": {
        "label": "Uppdateringar av produkten"
      },
      "mainBadge": {
        "label": "Nya"
      }
    },
    "wallet": {
      "main": {
        "label": "Plånbok"
      },
      "partnerPayments": {
        "label": "API-betalningar"
      },
      "verification": {
        "label": "Verifiering"
      },
      "walletManagement": {
        "label": "Plånbokshantering"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Tillgängliga leverantörer",
    "dismiss": "Avfärda",
    "navigate": "Navigera",
    "noResult": "Hittade inget resultat",
    "recentUsed": "Nyligen använt",
    "resultsLabel": "Hittade leverantörer",
    "searchError": "Något gick fel ...",
    "searchErrorMessage": "Vi arbetar med att lösa problemet. Försök snart igen.",
    "searchForVendor": "Sök leverantör",
    "select": "Välj",
    "storeResultsLabel": "Tillgängliga leverantörer",
    "supplierAlreadyInUseError": "Du använder för närvarande det här leverantörskontot",
    "switchVendor": "Byt leverantör",
    "wishmorning": "God morgon"
  }
}