import { inject, injectable } from 'inversify';

import { BOOKINGKIT_API_FACTORY, SUPPLIER_STATE_SERVICE } from '@/bootstrap/ServiceProviders';
import { BookingKitApiFactoryInterface } from '@/models/Factories/BookingKitApiFactoryInterface';
import { UseSupplierInterface } from '@/state/supplier';
import { PaginatedAndCancellableRequest } from '@/services/VoucherService/types';
import {
    CreateWidgetRequest,
    ListWidgetResponse,
    UpdateWidgetRequest,
} from '@bookingkit-private/api-v4';
import { fromApiWidgetToWidgetType } from '@/services/WidgetService/Reducers';
import { WidgetType } from '@/services/WidgetService/types';

@injectable()
export class WidgetService {
    private connection: ReturnType<BookingKitApiFactoryInterface['getConnection']>;

    private supplierId: string;

    constructor(
        @inject(BOOKINGKIT_API_FACTORY) factory: BookingKitApiFactoryInterface,
        @inject(SUPPLIER_STATE_SERVICE) useSupplierState: () => UseSupplierInterface,
    ) {
        const { supplier } = useSupplierState();
        this.supplierId = supplier.value!.id;
        this.connection = factory.getConnection();
        this.getAllWidgets = this.getAllWidgets.bind(this);
    }

    async getAllWidgets(params?: PaginatedAndCancellableRequest): Promise<WidgetType[]> {
        const { supplierId } = this;
        const parameters = params ?? {};
        const {
            pageSize, nextPageToken, signal,
        } = parameters;
        const { data } = await this.connection.listWidgets(supplierId,
            pageSize,
            1 as unknown as boolean,
            nextPageToken, undefined, { signal }) as unknown as ListWidgetResponse;
        const { data: widgets } = data as unknown as Required<ListWidgetResponse>;
        return widgets.map(fromApiWidgetToWidgetType);
    }

    async getWidget(widgetId: string): Promise<WidgetType> {
        const { data } = await this.connection.getWidget(widgetId);
        return fromApiWidgetToWidgetType(data);
    }

    async createNewWidget({ title, description }:{title: string, description?: string}): Promise<WidgetType> {
        const { supplierId } = this;
        const createWidgetRequest: CreateWidgetRequest = {
            title,
            description,
        };
        const { data: widget } = await this.connection
            .createWidget(supplierId, createWidgetRequest);
        return fromApiWidgetToWidgetType(widget);
    }

    async updateWidget({
        id, title, description, config,
    }:{id: string, title?: string, description?: string, config: any}): Promise<void> {
        const { supplierId } = this;
        const updateWidgetRequest: UpdateWidgetRequest = {
            title,
            description,
            config,
        };
        await this.connection.updateWidget(supplierId, id, updateWidgetRequest);
    }
}

export default { WidgetService };
